
* {
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

// Scrollbar on chrome
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #222;
  border: 1px solid transparent;
  border-radius: 50px;

  &:hover {
    background: #333;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 53px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Dark theme colors */
body.dark-theme {
  --text-color: #eee;
  --icon-color: #eee;
  --shadow-color: black;
  --btn-color: #111;
  --bkg-color: #333;
  --bkg-l-color: #444;
  --bkg-d-color: #222;
  --input-bkg-color: #f7f7f7;
  --input-border-color: #f7f7f7;
  --input-text-color: #111;
  --hover-color: #0075ff;
  --icon-hover-color: #0075ff;
  --error-color: #ff2020;

  color-scheme: dark;
  color: var(--text-color);
  background: black;
}

body.light-theme {
  --text-color: #222;
  --icon-color: #000;
  --shadow-color: #1af;
  --btn-color: #fff;
  --bkg-color: #eee;
  --bkg-l-color: #ddd;
  --bkg-d-color: #bbb;
  --input-bkg-color: white;
  --input-border-color: #111;
  --input-text-color: #111;
  --hover-color: #80bbff;
  --icon-hover-color: #0075ff;
  --error-color: #ff2020;

  color-scheme: light;
  color: var(--text-color);
  background: white;
}

/* Default colors */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  background: var(--bkg-color);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  .separator {
    width: 20px;
  }
}

a {
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: calc(100vh - 40px);

  // Pattern from SVGBackgrounds.com
  background-color: #330055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%233a015d' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23410165' cx='50' cy='100' r='50'/%3E%3Cg fill='%2348026e' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23500376' cx='50' cy='200' r='50'/%3E%3Cg fill='%2357047e' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%235f0587' cx='50' cy='300' r='50'/%3E%3Cg fill='%2367068f' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%236f0798' cx='50' cy='400' r='50'/%3E%3Cg fill='%237707a0' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%238008a9' cx='50' cy='500' r='50'/%3E%3Cg fill='%238909b1' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%239109ba' cx='50' cy='600' r='50'/%3E%3Cg fill='%239a09c3' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a309cb' cx='50' cy='700' r='50'/%3E%3Cg fill='%23ad09d4' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b608dc' cx='50' cy='800' r='50'/%3E%3Cg fill='%23c007e5' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c905ee' cx='50' cy='900' r='50'/%3E%3Cg fill='%23d303f6' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23D0F' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}

.app {
  width: calc(100% - 20px);
  max-width: 700px;
  padding: 20px;
  border-radius: 5px;
  background: var(--bkg-color);
}

.tabs {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
  background: var(--bkg-l-color);
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px dotted gray;
  border-bottom: 1px dotted gray;

  .inner {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: auto;
    margin-left: auto;
    width: max-content;

    > button:not(:last-child) {
      margin-right: 10px;
    }
  }

  button {
    background: var(--bkg-color);
    text-transform: uppercase;
    border-radius: 1px;
  }

  .selected {
    background: var(--bkg-d-color);
    border-color: white;
  }
}

.master-password {
  margin-top: 10px;

  label {
    display: none;
    padding: 3px;
    font-weight: bold;
    width: 100%;
  }

  .field {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    .text-input {
      flex-grow: 1;
      padding-right: 5px;

      input {
        width: 100%;
        height: 34px;
        margin: 0;
        font-size: 1.25rem;

        &:placeholder-shown {
          font-size: 1rem;
        }
      }
    }

    .text-hash {
      display: flex;
      flex-flow: row nowrap;
      margin: 0;

      .background {
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;
        padding: 5px;

        .color-box {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: white;
          text-shadow: 0 1px 1px black, 0 -1px 1px black, 1px 0 1px black, -1px 0 1px black;
        }
      }
    }
  }
}

.help {
  margin-top: 20px;
  margin-bottom: 10px;
}

.service-list, .alphabet-list, .secret-list, .single-use {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 10px;

  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;

    .add-service, .add-alphabet {
      width: 8rem;
    }

    .search {
      margin-left: 10px;
      height: 24px;
    }
  }

  .no-results {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .service, .alphabet, .secret {
    padding: 5px 10px;
    background: var(--bkg-l-color);
    border: 1px solid var(--bkg-d-color);
    border-radius: 5px;
    margin-bottom: 10px;

    .first-row {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      width: 100%;

      @media(max-width: 500px) {
        flex-flow: column;
      }

      .service-name, .secret-name {
        flex-grow: 1;
        font-weight: bold;

        @media(max-width: 500px) {
          padding-bottom: 10px;
        }
      }

      .service-inputs, .secret-inputs {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-end;

        &.invalid {
          .copy-btn {
            color: var(--error-color);
            cursor: not-allowed;
            user-select: none;
            pointer-events: none;
          }

          .pass-input {
            color: var(--error-color);
            cursor: not-allowed;
            user-select: none;
            pointer-events: none;
          }
        }

        @media(max-width: 500px) {
          width: 100%;
        }
      }

      .pass-input {
        text-align: center;
        border-radius: 3px;
        border: none;
        font-size: 1rem;
        height: 24px;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 130px;

        @media(min-width: 600px) {
          max-width: 240px;
        }
        @media(max-width: 500px) {
          font-size: 0.8rem;
          max-width: 100%;
          flex-grow: 1;
        }
      }

      .alphabet-name {
        display: block;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media(max-width: 500px) {
          min-width: 100%;
          width: 100%;
          text-align: center;
        }
      }

      .alphabet-inputs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-flow: row nowrap;
        flex-grow: 1;

        @media(max-width: 500px) {
          width: 100%;
        }
      }

      .alphabet-summary {
        max-width: 15rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media(max-width: 500px) {
          max-width: unset;
          width: calc(100% - 30px);
        }
      }
    }

    .secret-contents {
      margin-top: 10px;
      width: 100%;
    }
  }

  .copy-btn {
    margin-right: 5px;

    .checked {
      color: var(--icon-hover-color);
      filter: drop-shadow(1px 1px 2px var(--shadow-color));
      animation: icon-checked 0.25s ease-out;

      @keyframes icon-checked {
        from {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-5px);
        }
        to {
          transform: translateY(0px);
        }
      }
    }
  }

  .show-btn, .hide-btn {
    margin-left: 3px;
    margin-right: 3px;
  }

  .alphabet .edit-btn {
    margin-left: 5px;
  }

  .single-use-contents {
    display: flex;
    flex-flow: column;
    align-items: center;

    .first-row {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: center;

      margin-top: 20px;
      margin-bottom: 20px;

      @media(max-width: 500px) {
        width: 100%;
      }

      .pass-input {
        height: 30px;
        min-width: 300px;
        border-radius: 5px;
        text-align: center;
        font-size: 1.2em;

        @media(max-width: 500px) {
          min-width: min(200px, 60vw);
        }

        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: 10px;
      max-width: 300px;
      flex-flow: column;
      align-items: stretch;

      label {
        margin-bottom: 3px;
      }

      #single-use-code {
        height: 30px;
      }
    }

    .list-footer {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .add-service {
        min-width: 8rem;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.service-settings, .alphabet-settings, .secret-settings, .export-settings, .app-settings {
  padding: 10px;

  h3 {
    font-size: 1rem;
  }

  .sub-settings {
    margin-top: 10px;
    padding-left: 20px;
    padding-top: 1px;
    padding-bottom: 10px;
    //outline: 1px solid var(--input-border-color);
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    @media(max-width: 500px) {
      flex-flow: column;
      align-items: stretch;

      &.checkbox {
        flex-flow: row nowrap;
        align-items: center;
      }
    }

    &.vertical {
      flex-flow: column;
    }

    &.centered {
      justify-content: center;
    }

    &.invalid {
      input[type="text"] {
        color: var(--error-color);
      }
    }

    &.flex label {
      display: flex;
      align-items: center;
    }

    label {

      a {
        margin-left: 5px;
        margin-right: 5px;
      }

      @media(max-width: 500px) {
        margin-bottom: 3px;
      }

      svg {
        margin-left: 5px;
      }
    }

    &.checkbox label {
      cursor: pointer;
      user-select: none;
      margin-right: 5px;
    }

    input {
      min-width: 50%;
      height: 24px;
    }

    input[type="checkbox"] {
      min-width: unset;
      cursor: pointer;
      height: 24px;
      width: 24px;
    }

    select {
      max-width: 50%;
      @media(max-width: 500px) {
        max-width: unset;
      }
    }

    #defaultPasswordLength {
      min-width: 25%;
    }
  }

  .remove-btn, .load-btn {
    margin-top: 10px
  }
}

.export {
  margin-top: 10px;

  .export-import {
    margin-top: 10px;

    .export-result {
      padding: 10px;
      background: mix(gold, black, 30%);
      border: 1px solid gold;
      color: mix(gold, white, 30%);
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;

      &.success {
        background: transparentize(mix(green, white, 30%), 0.25);
        border: 1px solid green;
        color: mix(green, black, 30%);
      }

      &.error {
        background: transparentize(mix(red, black, 30%), 0.25);
        border: 1px solid red;
        color: mix(red, white, 30%);
      }
    }

    textarea {
      width: 100%;
    }

    .actions {
      display: flex;
      flex-flow: column;
      gap: 10px;
      padding-bottom: 10px;
      padding-top: 0;

      .actions-import, .actions-export {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 10px;
      }

      .row.checkbox {
        margin: 0;
      }
    }
  }
}

.app-settings {
  padding: 0 20px;

  @media (max-width: 500px) {
    padding: 0;

    .sub-settings {
      padding-left: 10px;
    }
  }
}

// Random

h1 {
  width: 100%;
  margin: 0;
  font-size: 2.0rem;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  font-family: FasterOne, sans-serif;

  @media(max-width: 500px) {
    font-size: 1.5rem;
  }
}

h2, h3 {
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

select {
  display: block;
  line-height: 1.3;
  padding: 0 25px 0 4px;
  height: 24px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: var(--input-bkg-color);
  color: var(--input-text-color);
  border: 1px solid var(--input-border-color);

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #888;
  }

  option {
    font-weight: normal;
  }
}

input[type="text"], input[type="number"], input[type="password"], textarea {
  background: var(--input-bkg-color);
  color: var(--input-text-color);
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  margin: 0;
  padding: 3px 10px;
}

textarea {
  padding: 10px 10px;
}

.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: var(--icon-color);
  border-radius: 3px;
  border: none;
  height: 24px;
  width: 24px;
  font-size: 24px;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: var(--icon-hover-color);
  }
}

.btn {
  background: var(--btn-color);
  color: var(--text-color);
  border: 1px solid #8e8e8e;
  user-select: none;
  font-weight: bold;
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: var(--hover-color);
  }

  &:disabled {
    background: var(--bkg-l-color);
    cursor: not-allowed;

    &:hover {
      background: var(--bkg-l-color);
    }
  }
}

.help-icon {
  cursor: help;
}

.warning-label {
  color: var(--error-color);
  cursor: help;
  margin-left: 5px;
}

.keep-apart {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.qrcode-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .qrcode-background {
    background: white;
    padding: 4px;
    width: min-content;
    margin: 4px;

    svg {
      display: block;
    }
  }
}

.mt {
  margin-top: 10px;
}

.mb {
  margin-top: 10px;
}